export const oneYearFromNowInSeconds = () => {
  const date = new Date()
  date.setFullYear(date.getFullYear() + 1)
  return date.getTime() / 1000
}

export const getCookieExpirationDate = (expirationDate) => {
  const currentDate = new Date().getTime() / 1000
  return expirationDate && (expirationDate > currentDate)
    ? expirationDate
    : oneYearFromNowInSeconds()
}
