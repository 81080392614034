/* eslint-disable class-methods-use-this */
import { IdmService } from '../kmp'
import { createComputedPropertyLessClone } from '../../helpers/jsonHelper'

export default class AddressAPI {
  async getAddresses() {
    return IdmService.getAddresses()
      .then((response) => createComputedPropertyLessClone(response))
  }

  async addAddress(address) {
    return IdmService.addAddress(address)
      .then((response) => createComputedPropertyLessClone(response))
  }

  async updateAddress(id, address) {
    return IdmService.updateAddress(id, address)
      .then((response) => createComputedPropertyLessClone(response))
  }

  async updateAddressAsPreferred(id) {
    return IdmService.updateAddressAsPreferred(id)
      .then((response) => createComputedPropertyLessClone(response))
  }

  async updateAddressAsNotPreferred(id) {
    return IdmService.updateAddressAsNotPreferred(id)
      .then((response) => createComputedPropertyLessClone(response))
  }

  async deleteAddress(id) {
    return IdmService.deleteAddress(id)
      .then((response) => createComputedPropertyLessClone(response))
  }
}
