/* eslint-disable import/prefer-default-export */
import { isLocalDomain } from './domainHelper'

export const importWebComponent = ({ server, component, sdkVersion }) => new Promise((resolve) => {
  let webComponentsFolderUrl = server.getSdkUrl(`v${sdkVersion}`)
  if (isLocalDomain()) webComponentsFolderUrl = window.location.origin
  const script = document.createElement('script')
  script.src = `${webComponentsFolderUrl}/web-components/${component}.js`
  script.type = 'module'
  script.onload = () => {
    const webComponent = document.createElement(component)
    document.body.appendChild(webComponent)
    resolve(webComponent)
  }
  document.head.appendChild(script)
})
