// eslint-disable-next-line import/extensions
import {
  // Objects
  IdentitySDK as KMPIdentitySDK,
  IdmService as KMPIdmService,
  AnalyticsService as KMPAnalyticsService,
  LogHelpers as KMPLogHelpers,
  EventService,
  SharedIdentitySDK,
  IdmServerErrorTypeNames as KMPIdmServerErrorTypeNames,

  // Other types used in this file
  getBirthYearOptionsAsArray,
  maskEmailAddress,
  validateEmailWithServerRegex,
  EventParams,
  PageName,
} from 'identity-sdk-core'

export {
  getTextAndCursorPositionOnTelephoneEnter,
  getTextAndCursorPositionOnTelephoneDelete,

  // Analytics
  EventParams,
  PageName,
  SignInType,
  SignOutType,
  OpenType,
  ClickLocation,
  ConditionType,
  ErrorSystem as AnalyticsErrorSystem,
  ErrorType as AnalyticsErrorType,
  AuthenticationFlow,
  AnalyticsEventMetadata,

  // State Machine
  AuthenticationNavigationStateMachine,

  // Zero Bounce
  EmailValidationStatus,

  // MParticle
  SignOutEventParams,

  // Passkey
  PasskeyAuthMode,

  // Models
  FirstPartyDataInputField,
  Profile,
  Gender,
  DataInputFieldConfiguration,

  // Helpers
  mapOf,
  meetsRequirements as isProfileValid,
  shippingAddressComplete as isShippingAddressValid,
} from 'identity-sdk-core'

export const IdentitySDK = KMPIdentitySDK.getInstance()
export const IdmService = KMPIdmService.getInstance()
export const AnalyticsEventDispatcher = EventService.getInstance()
export const AnalyticsService = KMPAnalyticsService.getInstance()
export const LogHelpers = KMPLogHelpers.getInstance()
export const IdmServerErrorTypeNames = KMPIdmServerErrorTypeNames.getInstance()

export function configureIdentitySdk(
  config,
  language,
  debug,
  eventLogger,
  remoteConfigProvider,
  covaticEventLogger,
) {
  IdentitySDK.init(
    config.forgeRock.apiUrl,
    config.forgeRock.apiVersion,
    config.zeroBounceApiUrl,
    config.brand.source,
    config.brand.product,
    language,
    eventLogger,
    covaticEventLogger,
    config.sessionTimeout,
    debug,
    remoteConfigProvider,
  )
}

export function setSdkVersion(version) {
  IdentitySDK.setSdkVersion(version)
}

export function setLanguageCode(languageCode) {
  IdentitySDK.setLanguageCode(languageCode)
}

export function setSessionToken(token) {
  IdentitySDK.setSessionToken(token)
}

export function getSessionToken() {
  return IdentitySDK.getSessionToken()
}

export function getSavedProfile() {
  return IdentitySDK.getSavedProfile()
}

export function maskEmail(email) {
  return maskEmailAddress(email)
}

export function validateEmailLocally(email) {
  return validateEmailWithServerRegex(email)
}

export function validatePasswordLocally() {
  return IdmService.validatePasswordLocally()
}

export function getBirthYearOptions(currentYear) {
  return getBirthYearOptionsAsArray(currentYear)
}

export function getHealth(environment) {
  return IdmService.getHealth(environment)
}

export function createEventParams(
  pageName,
  openType,
  registrationReferrer = null,
  referringPage = null,
) {
  return new EventParams(
    pageName.pageEventName,
    registrationReferrer ?? 'NBC Login',
    referringPage ?? 'None',
    pageName.pageType.pageTypeEventName,
    openType.openTypeEventName,
  )
}

export function getDeviceId() {
  return SharedIdentitySDK.getInstance().deviceId
}

export function getCompleteProfilePageName(newAccount) {
  return newAccount ? PageName.COMPLETE_PROFILE_CREATE : PageName.COMPLETE_PROFILE_LOGIN
}
