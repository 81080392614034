import { encode as base64encode } from 'base64-arraybuffer'
import { cookieNames, generateRandomGuid } from 'sdk-ui-web-library-common'
import { getCookie } from './cookieHelper'

const CODE_VERIFIER_STORAGE_NAME = 'nbcuni-code-verifier'
const TOKEN_URL = '/api/oauth2/access_token'

export const generateCodeChallengeAndRedirect = (
  codeVerifier,
  host,
  params = {},
  options = {},
) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const queryParams = new URLSearchParams()

  Object.entries(options).forEach(([key, value]) => {
    if (value) queryParams.set(key, value)
  })

  Object.entries(params).forEach(([key, value]) => {
    if (value) queryParams.set(key, value)
  })

  window.crypto.subtle.digest('SHA-256', data).then(
    (res) => {
      const base64Digest = base64encode(res)
      const codeChallenge = base64Digest.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
      queryParams.set('crossDomain', 'true')
      queryParams.set('codeChallenge', codeChallenge)
      const oauthUrl = `${host}?${queryParams.toString()}`
      window.location.href = oauthUrl
    },
  )
}

export const generateCodeVerifier = () => {
  const dec2hex = (dec) => (`0${dec.toString(16)}`).substr(-2)

  const array = new Uint32Array(56 / 2)
  window.crypto.getRandomValues(array)
  const codeVerifier = Array.from(array, dec2hex).join('')
  sessionStorage.setItem(CODE_VERIFIER_STORAGE_NAME, codeVerifier)
  return codeVerifier
}

export const fetchAccessTokenHelper =
  (code, redirectUri, host, clientId, frApiVersion, brandSource) => {
    const codeVerifier = sessionStorage.getItem(CODE_VERIFIER_STORAGE_NAME)
    const data = new URLSearchParams()
    data.append('grant_type', 'authorization_code')
    data.append('code', code)
    data.append('code_verifier', codeVerifier)
    data.append('redirect_uri', redirectUri)
    data.append('client_id', clientId)

    return fetch(`${host}${TOKEN_URL}`, {
      method: 'post',
      body: data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'api-version': frApiVersion,
        'x-idm-brand-source': brandSource,
        idm_tx_ref: generateRandomGuid(brandSource),

      },
    })
  }

export const refreshAccessTokenHelper = (
  { refreshToken },
  host,
  clientId,
  frApiVersion,
  brandSource,
) => {
  const data = new URLSearchParams()
  data.append('grant_type', 'refresh_token')
  data.append('refresh_token', refreshToken)
  data.append('client_id', clientId)

  return fetch(`${host}${TOKEN_URL}`, {
    method: 'post',
    body: data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'api-version': frApiVersion,
      'x-idm-brand-source': brandSource,
      idm_tx_ref: generateRandomGuid(brandSource),
    },
  })
}

export function isAuthenticatedWithCrossDomain() {
  const parkFrAccess = getCookie(cookieNames.PARK_FR_ACCESS)
  const parkFrRefresh = getCookie(cookieNames.PARK_FR_REFRESH)
  return parkFrAccess || parkFrRefresh
}
