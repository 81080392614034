import { IDENTITY_SDK_VERSION } from '../version'

export const getSDKVersionFromScript = () => {
  const sdkScript = document.getElementById('nbc-identity-sdk-js')
  if (!sdkScript) {
    return IDENTITY_SDK_VERSION
  }
  return getVersionFromUrl(sdkScript.src)
}

export const getVersionFromUrl = (url) => {
  const versionPattern = /v(\d+[.\d+-]*)\//
  const match = url.match(versionPattern)

  if (!match) {
    return IDENTITY_SDK_VERSION
  }

  return match[1]
}
