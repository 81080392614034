// Need to disable this to keep signature
/* eslint-disable class-methods-use-this */

import { isEmptyObject } from '../helpers/jsonHelper'
import Logger from '../util/logger'
import { EventLoggerStatus } from '../consts'

const MPARTICLE_ACTION_TIMEOUT = 2000

/**
 * Supported events that developers can receive notifications for
 * @typedef {String} ObservableEvents
 * @property {String} READY - User will be notified when the mP SDK is ready.
 * @property {String} IDENTITY_CHANGE - User will be notified every time that
 * the mParticle User Identity changes.
 */
export const ObservableEvents = Object.freeze({
  READY: 'READY',
  IDENTITY_CHANGE: 'IDENTITY_CHANGE',
})

export default class WebEventLogger {
  #mParticleConfig

  #env

  #isDebug

  #logger

  #status = EventLoggerStatus.UNINITIALIZED

  #pendingEvents = []

  customEventAttributes = {}

  #eventListeners = {}

  #analyticsEnv = 'production'

  constructor(mParticleConfig, isDebug, env, customEventAttributes, analyticsEnv) {
    this.#mParticleConfig = mParticleConfig
    this.#isDebug = isDebug
    this.#env = env
    this.#logger = new Logger('WebEventLogger', isDebug)
    this.customEventAttributes = customEventAttributes
    this.#analyticsEnv = analyticsEnv
  }

  get isReady() {
    return this.#status === EventLoggerStatus.READY
  }

  get isInitialized() {
    return this.isReady || this.#status === EventLoggerStatus.INITIALIZED
  }

  #checkIsCorrectIdentity(profile) {
    const currentUser = window.mParticle?.Identity?.getCurrentUser()
    const { userIdentities } = currentUser?.getUserIdentities() ?? {}

    const { customerid, email } = userIdentities ?? {}
    const { _id, mail } = profile ?? {}

    return _id === customerid && mail === email
  }

  addEventListener(eventKey, listener) {
    if (!(eventKey in ObservableEvents) || typeof listener !== 'function') return

    if (eventKey === ObservableEvents.READY && this.isReady) {
      listener?.()
    } else if (!this.#eventListeners[eventKey]) {
      this.#eventListeners[eventKey] = [listener]
    } else {
      this.#eventListeners[eventKey].push(listener)
    }

    if (eventKey === ObservableEvents.IDENTITY_CHANGE && this.isReady) {
      this.#notifyIdentityChange(listener)
    }
  }

  removeEventListener(eventKey, listener) {
    const listeners = this.#eventListeners[eventKey] ?? []
    if (listeners.includes(listener)) {
      this.#eventListeners[eventKey] = listeners.filter((item) => (
        item !== listener
      ))
    }
  }

  async #notifyListeners(eventKey, ...args) {
    const listeners = this.#eventListeners[eventKey] ?? []
    listeners.forEach((listener) => (async () => {
      listener?.(...args)
    })())
  }

  async setUp(profile, dataPlan) {
    if (this.#status === EventLoggerStatus.UNINITIALIZED) {
      this.#status = EventLoggerStatus.INITIALIZING

      const userIdentities = {}
      if (profile) {
        userIdentities.customerid = profile._id
        userIdentities.email = profile.mail
      }

      let deviceId = new URLSearchParams(window.location.search).get('mParticleDeviceID')
      if (!deviceId) deviceId = window.fpcapi?.config.MP_DEVICE_ID
      const config = {
        isDevelopmentMode: this.#env !== 'production',
        logLevel: this.#isDebug ? 'verbose' : 'none',
        identifyRequest: { userIdentities },
        dataPlan,
        deviceId,
      }

      if (this.#mParticleConfig.customDomain) {
        Object.assign(config, {
          v1SecureServiceUrl: `${this.#mParticleConfig.customDomain}/webevents/v1/JS/`,
          v2SecureServiceUrl: `${this.#mParticleConfig.customDomain}/webevents/v2/JS/`,
          v3SecureServiceUrl: `${this.#mParticleConfig.customDomain}/webevents/v3/JS/`,
          configUrl: `${this.#mParticleConfig.customDomain}/tags/JS/v2/`,
          identityUrl: `${this.#mParticleConfig.customDomain}/identity/v1/`,
          aliasUrl: `${this.#mParticleConfig.customDomain}/webevents/v1/identity/`,
        })
      }

      this.#initMParticle(this.#mParticleConfig, config, this.#analyticsEnv)

      if (window.mParticle) {
        this.#status = EventLoggerStatus.INITIALIZED

        window.mParticle.ready(() => {
          /**
           * Seems that the mP SDK executes the ready callback,
           * even when the user is still being identitied.
           * So, we wait until getCurrentUser() returns
           * something (not null) before changing to ready.
           */
          const intervalId = setInterval(() => {
            if (window.mParticle.Identity.getCurrentUser()) {
              clearInterval(intervalId)
              this.#setReady(profile)
            }
          }, 250)
        })
      } else {
        this.#status = EventLoggerStatus.ERROR

        // eslint-disable-next-line no-console
        console.warn(
          'Trying to initialize MParticle but brand hasn\'t provide any key or implemented it!',
        )
      }
    }
  }

  getCurrentUser() {
    return new Promise((resolve) => {
      this.addEventListener(ObservableEvents.READY, () => {
        resolve(window.mParticle.Identity.getCurrentUser())
      })
    })
  }

  async getMPID() {
    const currentUser = await this.getCurrentUser()
    return currentUser?.getMPID()
  }

  async #notifyIdentityChange(listener) {
    const currentUser = await this.getCurrentUser()
    const mPID = currentUser?.getMPID()
    const { userIdentities } = currentUser?.getUserIdentities() || {}
    if (listener) (async () => listener(mPID, userIdentities))()
    else this.#notifyListeners('IDENTITY_CHANGE', mPID, userIdentities)
  }

  logout() {
    this.#logger.log('[Logout] Starting MParticle Identity Change')
    let isResolved = false
    return new Promise((resolve) => {
      this.addEventListener(ObservableEvents.READY, () => {
        window.mParticle.Identity.logout({}, () => {
          this.#notifyIdentityChange()

          if (isResolved) {
            this.#logger.log('[Logout] MParticle Identity change completed after the timeout!')
          } else {
            this.#logger.log('[Logout] MParticle Identity change completed')
            isResolved = true
            resolve()
          }
        })
      })

      setTimeout(() => {
        if (!isResolved) {
          isResolved = true
          this.#logger.warn('[Logout] MParticle Identity Change request timed out!')
          resolve()
        }
      }, MPARTICLE_ACTION_TIMEOUT)
    })
  }

  login(identity) {
    this.#logger.log('[Login] Starting MParticle Identity Change')
    let isResolved = false
    return new Promise((resolve) => {
      this.addEventListener(ObservableEvents.READY, () => {
        window.mParticle.Identity.login({ userIdentities: identity }, () => {
          this.#notifyIdentityChange()

          if (isResolved) {
            this.#logger.log('[Login] MParticle Identity change completed after the timeout!')
          } else {
            this.#logger.log('[Login] MParticle Identity change completed')
            isResolved = true
            resolve()
          }
        })
      })

      setTimeout(() => {
        if (!isResolved) {
          isResolved = true
          this.#logger.warn('[Login] MParticle Identity Change request timed out!')
          resolve()
        }
      }, MPARTICLE_ACTION_TIMEOUT)
    })
  }

  updateIdentity(identity) {
    this.#logger.log('[Modify] Starting MParticle Identity Change')
    let isResolved = false

    return new Promise((resolve) => {
      this.addEventListener(ObservableEvents.READY, () => {
        window.mParticle.Identity.modify({ userIdentities: identity }, () => {
          this.#notifyIdentityChange()

          if (isResolved) {
            this.#logger.log('[Modify] MParticle Identity change completed after the timeout!')
          } else {
            this.#logger.log('[Modify] MParticle Identity change completed')
            isResolved = true
            resolve()
          }
        })
      })

      setTimeout(() => {
        if (!isResolved) {
          isResolved = true
          this.#logger.warn('[Modify] MParticle Identity Change request timed out!')
          resolve()
        }
      }, MPARTICLE_ACTION_TIMEOUT)
    })
  }

  updateUserAttributes(attributes) {
    this.addEventListener(ObservableEvents.READY, () => {
      const currentUser = window.mParticle.Identity.getCurrentUser()
      currentUser?.setUserAttributes(attributes)
    })
  }

  logEvent(name, type, customAttributes, flags, options) {
    const allAttributes = { ...customAttributes, ...this.customEventAttributes }
    if (this.isReady) {
      window.mParticle.logEvent(
        name,
        window.mParticle.EventType[type],
        allAttributes,
        flags,
        options,
      )
    } else {
      this.#pendingEvents.push({ name, type, customAttributes, flags, options })
    }
  }

  #processQueuedEvents() {
    this.#pendingEvents.forEach(({ name, type, customAttributes, flags, options }) => {
      this.logEvent(name, type, customAttributes, flags, options)
    })
    this.#pendingEvents.length = 0
  }

  /**
   * 1. If the user is not authenticated and the current mParticle user is authenticated,
   * we should run a logout to avoid events that can be dispatched in the wrong user.
   *
   * 2. If the user is authenticated and the current mParticle user is different,
   * we should log out the previous mP identity and then log in the new one.
   */
  async #logoutIfRequired(profile) {
    return new Promise((resolve) => {
      try {
        const currentUser = window.mParticle.Identity.getCurrentUser()
        const { userIdentities } = currentUser?.getUserIdentities() ?? {}

        const isCurrentUserAnonymous = !userIdentities || isEmptyObject(userIdentities)
        const isCorrectUserIdentity = this.#checkIsCorrectIdentity(profile)

        const shouldLogOut = !isCurrentUserAnonymous && !isCorrectUserIdentity

        if (shouldLogOut) {
          window.mParticle.Identity.logout({}, () => {
            if (profile) {
              const newIdentity = { customerid: profile._id, email: profile.mail }
              window.mParticle.Identity.login({ userIdentities: newIdentity }, () => {
                resolve()
              })
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      } catch (error) {
        resolve()
      }
    })
  }

  async #setReady(profile) {
    await this.#logoutIfRequired(profile)
    this.#status = EventLoggerStatus.READY
    this.#processQueuedEvents()
    this.#notifyListeners(ObservableEvents.READY)
    this.#notifyIdentityChange()
  }

  #initMParticle(mParticleConfig, config, analyticsEnv) {
    if (window.mParticle || this.isInitialized) {
      // eslint-disable-next-line no-console
      console.warn('Trying to initialize MParticle but it\'s already initialized! This call will be discarded!')
      return
    }

    this.#logger.log('MParticle init config', config)

    let mParticleApiKey = mParticleConfig.apiKey
    const customBaseURL = this.#mParticleConfig.customDomain ? `https://${this.#mParticleConfig.customDomain}` : null

    if (analyticsEnv === 'stage') {
      mParticleApiKey = mParticleConfig.testApiKey
      if (!mParticleConfig.testApiKey) {
        this.#logger.log('analyticsEnv set to stage but testApiKey and testApiSecret are not provided for mParticle, falling back to using apiKey and apiSecret', config)
        mParticleApiKey = mParticleConfig.apiKey
      }
    }

    window.mParticle = { config };

    /* eslint-disable */
    (function (t) {
      window.mParticle = window.mParticle || {};
      window.mParticle.EventType = {
        Unknown: 0,
        Navigation: 1,
        Location: 2,
        Search: 3,
        Transaction: 4,
        UserContent: 5,
        UserPreference: 6,
        Social: 7,
        Other: 8,
      };
      window.mParticle.eCommerce = { Cart: {} };
      window.mParticle.Identity = {};
      window.mParticle.config = window.mParticle.config || {};
      window.mParticle.config.rq = [];
      window.mParticle.config.snippetVersion = 2.3;
      window.mParticle.ready = function (t) {
        window.mParticle.config.rq.push(t);
      };
      var e = [
        "endSession",
        "logError",
        "logBaseEvent",
        "logEvent",
        "logForm",
        "logLink",
        "logPageView",
        "setSessionAttribute",
        "setAppName",
        "setAppVersion",
        "setOptOut",
        "setPosition",
        "startNewSession",
        "startTrackingLocation",
        "stopTrackingLocation",
      ];
      var o = ["setCurrencyCode", "logCheckout"];
      var i = ["identify", "login", "logout", "modify"];
      e.forEach(function (t) {
        window.mParticle[t] = n(t);
      });
      o.forEach(function (t) {
        window.mParticle.eCommerce[t] = n(t, "eCommerce");
      });
      i.forEach(function (t) {
        window.mParticle.Identity[t] = n(t, "Identity");
      });
      function n(e, o) {
        return function () {
          if (o) {
            e = o + "." + e;
          }
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          window.mParticle.config.rq.push(t);
        };
      }
      var dpId,
        dpV,
        config = window.mParticle.config,
        env = config.isDevelopmentMode ? 1 : 0,
        dbUrl = "?env=" + env,
        dataPlan = window.mParticle.config.dataPlan;
      dataPlan &&
        ((dpId = dataPlan.planId),
        (dpV = dataPlan.planVersion),
        dpId &&
          (dpV && (dpV < 1 || dpV > 1e3) && (dpV = null),
          (dbUrl += "&plan_id=" + dpId + (dpV ? "&plan_version=" + dpV : ""))));
      var mp = document.createElement("script");
      mp.type = "text/javascript";
      mp.async = true;
      if (customBaseURL) {
        mp.src = `${customBaseURL}/tags/JS/v2/` + t + "/mparticle.js" + dbUrl
      } else {
        mp.src=("https:"==document.location.protocol?"https://jssdkcdns":"http://jssdkcdn")+".mparticle.com/js/v2/"+t+"/mparticle.js" + dbUrl
      }
      var c = document.getElementsByTagName("script")[0];
      c.parentNode.insertBefore(mp, c);
    })(mParticleApiKey);
    /* eslint-enable */
  }
}
