export const isPasskeySupported = async () => {
  if (!window.PublicKeyCredential) {
    return false
  }

  if (typeof window.PublicKeyCredential.isConditionalMediationAvailable === 'function') {
    try {
      const isPasskeyAvailable = await window.PublicKeyCredential.isConditionalMediationAvailable()
      return isPasskeyAvailable
    } catch {
      return false
    }
  }

  return false
}

export const createPublicKey = (metadata) => {
  if (!window.PublicKeyCredential) {
    return {}
  }

  const challenge = Uint8Array.from(atob(metadata.challenge), (c) => c.charCodeAt(0))
  const excludeCredentials = metadata._excludeCredentials === '' ? [] : metadata._excludeCredentials

  // Passkey API requires the excluded credentials ids to be an Uint8Array
  excludeCredentials.forEach((cred) => {
    if (cred.id && Array.isArray(cred.id)) {
      cred.id = Uint8Array.from(cred.id)
    }
  })

  return {
    challenge,
    excludeCredentials,
    rp: {
      id: metadata._relyingPartyId,
      name: metadata.relyingPartyName,
    },
    user: {
      id: Uint8Array.from(atob(metadata.userId), (c) => c.charCodeAt(0)),
      name: metadata.userName,
      displayName: metadata.displayName,
    },
    pubKeyCredParams: JSON.parse(metadata.pubKeyCredParams),
    attestation: metadata.attestationPreference,
    timeout: metadata.timeout,
    authenticatorSelection: {
      ...JSON.parse(metadata.authenticatorSelection),
    },
  }
}
