export const iframeBaseId = 'identity-window-iframe'
export const iframeWrapperId = `${iframeBaseId}-wrapper`

const createElement = (id, elementType = 'div') => {
  const element = document.createElement(elementType)
  element.id = id
  return element
}

export const createIFrameElement = (url) => {
  const iframe = createElement(iframeBaseId, 'iframe')
  iframe.src = url
  iframe.frameBorder = 0

  return iframe
}

export const addIframeModal = (url) => {
  const existingIframe = document.getElementById(iframeWrapperId)
  if (existingIframe) return existingIframe.querySelector('iframe')

  const iframe = createIFrameElement(url)

  const modal = createElement(`${iframeBaseId}-modal`)
  const wrapper = createElement(iframeWrapperId)

  modal.appendChild(iframe)
  wrapper.appendChild(modal)

  document.body.appendChild(wrapper)
  document.body.style.overflow = 'hidden'

  return iframe
}

export const addIframeModalStyle = () => new Promise((resolve) => {
  const styleId = 'identity-window-style'

  const existingStyleBlock = document.getElementById(styleId)
  if (existingStyleBlock) {
    resolve(existingStyleBlock)
    return
  }

  const styleBlock = createElement(styleId, 'style')
  styleBlock.type = 'text/css'
  styleBlock.appendChild(document.createTextNode(`
      #${iframeWrapperId} {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 9999999;
      }

      #${iframeBaseId}-modal {
        width: 375px;
        height: 522px;
        position: relative;
        max-width: 100%;
        max-height: 100%;
        box-sizing: border-box;
        border-radius: 8px;
        overflow: hidden;
        transition: all 250ms ease-in-out;
        transition-property: width, height;
      }

      @media screen and (max-width: 600px) {
        #${iframeBaseId}-modal {
          min-width: 100%;
          min-height: 100%;
          min-height: -webkit-fill-available;
          border-radius: 0px;
          transition: none;
        }
      }

      #${iframeBaseId} {
        width: 100%;
        height: 100%;
      }

    `))

  styleBlock.addEventListener('load', () => resolve(styleBlock))

  document.head.appendChild(styleBlock)
})
