import { ConfigHelpers } from 'sdk-ui-web-library-common'
import { getExistingOptInAgreementIdentifiers } from './helpers/cookieHelper'
import { getSavedProfile } from './networking/kmp'

/**
 * Identity record
 *
 * @property {IdentityProfile} profile - Identity profile or null
 * @property {number} timestamp - Identity record timestamp
 */
class IdentityRecord {
  /**
     * Restore record from storage
     *
     * If the stored profile is invalid then this returns null.
     *
     * @param {IdentityConfig} config - Identity config
     * @return {IdentityRecord} - Identity record or null
     */
  static restore(config) {
    const savedProfile = getSavedProfile()
    return savedProfile
      ? new IdentityRecord({
        ...savedProfile,
        optInAgreementIdentifiers: getExistingOptInAgreementIdentifiers(
          savedProfile,
          ConfigHelpers.getConfigOptIns(config),
        ),
      }) : null
  }

  /**
     * IdentityRecord constructor
     *
     * @param {Object} properties - Properties
     * @param {IdentityProfile} properties.profile - Identity profile or null
     */
  constructor(profile) {
    Object.assign(this, { profile })

    // Disable property modification
    Object.freeze(this)
  }
}

export default IdentityRecord
