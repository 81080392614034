export const isLocalDomain = () => /^(3|4|5)[0-9]{1,3}$/.test(window.location.port)

export const getDomainFromEnv = (env) => (env === 'production' ? 'id.nbcuni.com' : 'id-envs.nbcuni.com')

export const getDynamicDomain = (env, crossDomain = false) => {
  if (isLocalDomain() && !crossDomain) return window.location.host
  if (crossDomain || isNBCUniDomain(env)) {
    return getDomainFromEnv(env)
  }
  return getDomainFromHostname()
}

export function getDomainFromHostname() {
  // We must get the last two-part from the domain, excluding the port
  const splitDomain = window.location.host.split(':')[0].split('.')
  return `${splitDomain[splitDomain.length - 2]}.${splitDomain[splitDomain.length - 1]}`
}

export function isNBCUniDomain(env) {
  return window.location.host.endsWith(getDomainFromEnv(env))
}
