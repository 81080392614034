import {
  AnalyticsEventMetadata,
  mapOf,
  IdentitySDK,
} from '../networking/kmp'
import { ObservableEvents } from '../eventLogger/WebEventLogger'

/**
 * @typedef {Object} AnalyticsEventMetadata
 * @property {string|null} mvpd
 * @property {boolean|null} isMVPDAuthenticated
 * @property {string|null} entitlement
 * @property {string|null} profile
 * @property {string|null} articleID
 * @property {string|null} author
 * @property {string|null} people
 * @property {number|null} publishedDate
 * @property {string|null} verticalName
 * @property {string|null} category
 * @property {string|null} topics
 * @property {string|null} showTitle
 * @property {string|null} showType
 * @property {string|null} utmCampaign
 * @property {string|null} utmContent
 * @property {string|null} utmMedium
 * @property {string|null} utmSource
 * @property {string|null} utmTags
 * @property {string|null} utmTerm
 * @property {string|null} sponsor
 */

export default class AnalyticsEventLogger {
  #webEventLogger

  constructor(webEventLogger) {
    this.#webEventLogger = webEventLogger
  }

  get isReady() {
    return this.#webEventLogger.isReady
  }

  get isInitialized() {
    return this.#webEventLogger.isInitialized
  }

  /**
    * Logs an event to mParticle's servers
    * @method logMParticleEvent
    * @param {String} eventNaapp.me The name of the event
    * @param {Number} [eventType] The eventType as seen
    *   [here](http://docs.mparticle.com/developers/sdk/web/event-tracking#event-type)
    * @param {Object} [eventInfo] Attributes for the event
    * @param {Object} [customFlags] Additional customFlags
    * @param {Object} [eventOptions] For Event-level Configuration Options
    */
  logMParticleEvent(eventName, eventType, eventInfo, customFlags, eventOptions) {
    if (!this.#webEventLogger.isReady) {
      console.warn('WebEventLogger is not ready. The event will be queued.', {
        eventName,
        eventType,
        eventInfo,
        customFlags,
        eventOptions,
      })
    }
    this.#webEventLogger.logEvent(eventName, eventType, eventInfo, customFlags, eventOptions)
  }

  /**
    * Initiate a modify request to the mParticle server
    * @method modifyMParticleIdentity
    * @param {Object} identityApiData The identityApiData object as indicated
    *   [here](https://github.com/mParticle/mparticle-sdk-javascript/blob/master-v2/README.md#1-customize-the-sdk)
    * @param {Function} [callback] A callback function that is called when the modify request
    * completes
    */
  modifyMParticleIdentity(identityApiData, callback) {
    if (this.#webEventLogger.isReady) {
      window.mParticle.Identity.modify(identityApiData, callback)
    } else {
      console.error("Couldn't modify MParticle identity. WebEventLogger is not ready.")
    }
  }

  addEventListener(eventKey, listener) {
    this.#webEventLogger.addEventListener(eventKey, listener)
  }

  removeEventListener(eventKey, listener) {
    this.#webEventLogger.removeEventListener(eventKey, listener)
  }

  getCurrentUser() {
    return this.#webEventLogger.getCurrentUser()
  }

  getMPID() {
    return this.#webEventLogger.getMPID()
  }

  /**
   * Set multiple user attributes
   * @method setMParticleUserAttributes
   * @param {Object} user attribute object with keys of the attribute type, and value of the
   * attribute value
   */
  async setMParticleUserAttributes(userAttributes) {
    const currentUser = await this.getCurrentUser()
    if (currentUser) {
      Object.entries(userAttributes).forEach((v) => currentUser.setUserAttribute(v[0], v[1]))
    } else {
      console.error('Couldn\'t set MParticle user attributes. There is no current user.')
    }
  }

  /**
   * Sets a user attribute
   * @method setMParticleUserAttribute
   * @param {String} key
   * @param {String} value
   */
  async setMParticleUserAttribute(key, value) {
    const currentUser = await this.getCurrentUser()
    if (currentUser) {
      currentUser.setUserAttribute(key, value)
    } else {
      console.error("Couldn't set MParticle user attribute. There is no current user.")
    }
  }

  /**
   * Sends the PageLoad event to all supported analytics Providers.
   * Parameters {pageName} and {pageType} are mandatory. Everything else is optional.
   *
   * @method sendPageLoadEvent
   * @param {string} pageName
   * @param {string} pageType
   * @param {string?} referringPage
   * @param {string?} registrationReferrer
   * @param {string?} openType
   * @param {number?} duration
   * @param {AnalyticsEventMetadata?} metadata
   * @param {Object?} additionalAttributes
   */
  sendPageLoadEvent({
    pageName,
    pageType,
    referringPage = null,
    registrationReferrer = null,
    openType = null,
    duration = null,
    metadata = null,
    additionalAttributes = null,
  }) {
    if (!pageName || !pageType) {
      console.error('PageName and PageType are required for the PageLoad event!')
      return
    }

    this.#identityAnalytics.sendPageLoadEvent(
      pageName,
      pageType,
      referringPage,
      registrationReferrer,
      openType,
      duration,
      AnalyticsEventMetadata.fromJSON(metadata),
      mapOf(additionalAttributes),
    )
  }

  /**
   * Sends the VideoStart event to all supported analytics Providers.
   * Parameters {pageName}, {pageType}, {videoID} and {videoType} are mandatory.
   * Everything else is optional.
   *
   * @method sendVideoStartEvent
   * @param {string} pageName
   * @param {string} pageType
   * @param {string} videoID
   * @param {string} videoType
   * @param {string?} referringPage
   * @param {string?} registrationReferrer
   * @param {string?} openType
   * @param {number?} videoDuration
   * @param {number?} playedDuration
   * @param {string?} contentRating
   * @param {string?} episodeNumber
   * @param {string?} episodeTitle
   * @param {string?} genre
   * @param {string?} league
   * @param {number?} season
   * @param {string[]?} sport
   * @param {string?} language
   * @param {string?} ccLanguage
   * @param {string?} previousVideoID
   * @param {string?} previousVideoType
   * @param {AnalyticsEventMetadata?} metadata
   * @param {Object?} additionalAttributes
   */
  sendVideoStartEvent({
    pageName,
    pageType,
    videoID,
    videoType,
    referringPage = null,
    openType = null,
    videoDuration = null,
    playedDuration = null,
    contentRating = null,
    episodeNumber = null,
    episodeTitle = null,
    genre = null,
    league = null,
    season = null,
    sport = null,
    language = null,
    ccLanguage = null,
    previousVideoID = null,
    previousVideoType = null,
    metadata = null,
    additionalAttributes = null,
  }) {
    if (!pageName || !pageType || !videoID || !videoType) {
      console.error(
        'PageName, PageType, VideoId and VideoType are required for the ViewStart event!',
      )
      return
    }

    this.#identityAnalytics.sendVideoStartEvent(
      pageName,
      pageType,
      videoID,
      videoType,
      referringPage,
      openType,
      videoDuration,
      playedDuration,
      contentRating,
      episodeNumber,
      episodeTitle,
      genre,
      league,
      season,
      sport,
      language,
      ccLanguage,
      previousVideoID,
      previousVideoType,
      AnalyticsEventMetadata.fromJSON(metadata),
      mapOf(additionalAttributes),
    )
  }

  /**
   * Sends the VideoStart event to all supported analytics Providers.
   * Parameters {pageName}, {pageType}, {videoID} and {videoType} are mandatory.
   * Everything else is optional.
   *
   * @method sendVideoStartEvent
   * @param {string} pageName
   * @param {string} pageType
   * @param {string} videoID
   * @param {string} videoType
   * @param {string?} openType
   * @param {string?} referringPage
   * @param {int?} videoDuration
   * @param {int?} playedDuration
   * @param {string?} genre
   * @param {string?} league
   * @param {int?} season
   * @param {string[]?} sport
   * @param {string?} previousVideoID
   * @param {string?} previousVideoType
   * @param {string?} show
   * @param {string?} episodeTitle
   * @param {string?} episodeNumber
   * @param {string?} casting
   * @param {string?} language
   * @param {string?} ccLanguage
   * @param {string?} callSign
   * @param {string?} geoStation
   * @param {string?} homeStation
   * @param {string?} contentRating
   * @param {AnalyticsEventMetadata?} metadata
   * @param {Object?} additionalAttributes
   */
  sendLinearStartEvent({
    pageName,
    pageType,
    videoID,
    videoType,
    openType = null,
    referringPage = null,
    videoDuration = null,
    playedDuration = null,
    genre = null,
    league = null,
    season = null,
    sport = null,
    previousVideoID = null,
    previousVideoType = null,
    show = null,
    episodeTitle = null,
    episodeNumber = null,
    casting = null,
    language = null,
    ccLanguage = null,
    callSign = null,
    geoStation = null,
    homeStation = null,
    contentRating = null,
    metadata = null,
    additionalAttributes = null,
  }) {
    if (!pageName || !pageType || !videoID || !videoType) {
      console.error(
        'PageName, PageType, VideoId and VideoType are required for the ViewStart event!',
      )
      return
    }

    this.#identityAnalytics.sendLinearStartEvent(
      pageName,
      pageType,
      videoID,
      videoType,
      openType,
      referringPage,
      videoDuration,
      playedDuration,
      genre,
      league,
      season,
      sport,
      previousVideoID,
      previousVideoType,
      show,
      episodeTitle,
      episodeNumber,
      casting,
      language,
      ccLanguage,
      callSign,
      geoStation,
      homeStation,
      contentRating,
      AnalyticsEventMetadata.fromJSON(metadata),
      mapOf(additionalAttributes),
    )
  }

  // eslint-disable-next-line class-methods-use-this
  get #identityAnalytics() {
    return IdentitySDK.analytics
  }

  // eslint-disable-next-line class-methods-use-this
  get ObservableEvents() {
    return ObservableEvents
  }
}
