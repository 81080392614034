import semver from 'semver'

export const checkVersionIsEqualTo = ({ currentVersion, expectedVersion }) => semver.eq(
  semver.coerce(currentVersion),
  semver.coerce(expectedVersion),
)

export const checkVersionIsAtLeast = ({ currentVersion, minVersion }) => semver.gte(
  semver.coerce(currentVersion),
  semver.coerce(minVersion),
)
