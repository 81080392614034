import { createEventParams as createKMPEventParams } from './kmp'

export default class BaseApi {
  constructor() {
    this.registrationReferrer = null
    this.referringPage = null
  }

  setReferralInfo(registrationReferrer, referringPage) {
    this.registrationReferrer = registrationReferrer
    this.referringPage = referringPage
  }

  createEventParams(pageName, openType) {
    return createKMPEventParams(
      pageName,
      openType,
      this.registrationReferrer,
      this.referringPage,
    )
  }
}
