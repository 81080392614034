/* eslint-disable class-methods-use-this */
import { IdmService, PasskeyAuthMode } from '../kmp'
import { createComputedPropertyLessClone } from '../../helpers/jsonHelper'
import { createPublicKey, isPasskeySupported } from '../../helpers/passkeyHelper'

export default class PasskeyAPI {
  async isPasskeySupported() {
    return isPasskeySupported()
  }

  #passkeyInitRegistration() {
    return IdmService.passkeyInit(PasskeyAuthMode.REGISTRATION)
      .then((response) => createComputedPropertyLessClone(response))
  }

  #passkeyFinalizeRegistrationWithSuccess(authId, clientData, keyData, rawId) {
    return IdmService.passkeyFinalizeRegistrationWithSuccess(authId, clientData, keyData, rawId)
      .then((response) => createComputedPropertyLessClone(response))
  }

  #passkeyFinalizeRegistrationWithError(authId, errorMessage) {
    return IdmService.passkeyFinalizeRegistrationWithError(authId, errorMessage)
      .then((response) => createComputedPropertyLessClone(response))
  }

  async createPasskey(abortController) {
    let authId
    try {
      const initResponse = await this.#passkeyInitRegistration()

      authId = initResponse.authcontext.authId
      const publicKey = createPublicKey(initResponse.authcontext.metadata)

      const newCredentialInfo = await navigator.credentials.create({
        publicKey,
        signal: abortController.signal,
      })

      const rawId = newCredentialInfo.id
      const clientData = String.fromCharCode.apply(
        null,
        new Uint8Array(newCredentialInfo.response.clientDataJSON),
      )
      const keyData = new Int8Array(newCredentialInfo.response.attestationObject).toString()

      const requestAuthId = authId
      authId = null

      return this.#passkeyFinalizeRegistrationWithSuccess(
        requestAuthId,
        clientData,
        keyData,
        rawId,
      )
    } catch (err) {
      return this.#passkeyFinalizeRegistrationWithError(authId, err)
    }
  }

  listPasskeys() {
    return IdmService.listPasskeys()
      .then((response) => response.map(createComputedPropertyLessClone))
  }

  deletePasskey(deviceId) {
    return IdmService.passkeyDelete(deviceId)
      .then(() => Promise.resolve())
  }

  renamePasskey(deviceId, newName) {
    return IdmService.passkeyRename(deviceId, newName)
      .then(() => Promise.resolve())
  }
}
