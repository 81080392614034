import { cookieNames, ConfigHelpers } from 'sdk-ui-web-library-common'
import { isInvalidValue } from './stringHelper'
import {
  getDomainFromEnv,
  getDynamicDomain,
  isLocalDomain,
  getDomainFromHostname,
  isNBCUniDomain,
} from './domainHelper'

import { getLocalStorageItem, localStorageItemNames } from './localStorageHelper'

const DATE_MAX_MILLISECONDS = 8640000000000
const OPT_IN_COOKIE_PREFIX = 'opt_in_agreement'

export const getCookie = (cookieName) => {
  const cookie = document.cookie.split(';').filter((item) => item.includes(`${cookieName}=`))

  if (cookie[0]) {
    const value = cookie[0].replace(`${cookieName}=`, '').trim()
    if (isInvalidValue(value)) return null
    return value
  }

  return null
}

export const setCookie = (cookieName, value, expirationDate, env, isCrossDomain) => {
  if (isInvalidValue(value)) return

  const cookieList = []
  const expires = expirationDate > 0
    ? new Date(expirationDate * 1000)
    : new Date(DATE_MAX_MILLISECONDS)

  cookieList.push(`${cookieName}=${value}`)
  cookieList.push(`Expires=${expires.toUTCString()}`)
  cookieList.push('Path=/')
  cookieList.push('SameSite=none')
  cookieList.push('Secure')

  if (!isLocalDomain()) {
    if (cookieName === cookieNames.PARK_FR_REFRESH || cookieName === cookieNames.PARK_FR_ACCESS) {
      cookieList.push(`Domain=.${getDomainFromHostname()}`)
    } else {
      cookieList.push(`Domain=.${getDynamicDomain(env, isCrossDomain)}`)
    }
  }

  document.cookie = cookieList.join(';')
}

/**
 * Verifies Cookie Value and calls cookie change handler on changes
 *
 * @param {string} cookieName
 * @param {function} cb
 *
 * @return {number} Interval id
 */
export const verifyCookieOnInterval = (cookieName, cb) => {
  let history = getCookie(cookieName)
  return window.setInterval(() => {
    const current = getCookie(cookieName)
    if (history !== current) {
      history = current
      cb(current)
    }
  }, 1000)
}

export const removeAllCookiesByName = (cookieName, envForHost) => {
  const cookies = document.cookie.split(';')

  cookies.forEach((cookie) => {
    const [name] = cookie.trim().split('=')

    if (name === cookieName) {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Max-Age=0; path=/; Domain=.${envForHost}`
    }
  })
}

export const removeCookie = (cookieName, env, isCrossDomain) => {
  if (!isLocalDomain()) {
    if (cookieName === cookieNames.PARK_FR_REFRESH || cookieName === cookieNames.PARK_FR_ACCESS) {
      document.cookie = `${cookieName}=; Max-Age=0;Domain=.${getDomainFromHostname()};path=/`
    } else {
      document.cookie = `${cookieName}=; Max-Age=0;Domain=.${getDynamicDomain(env, isCrossDomain)};path=/`
    }

    const envForHost = getDomainFromEnv(env)

    if (
      (cookieName === cookieNames.PARK_FR || cookieName === cookieNames.I_PLANET_DIRECTORY_PRO) &&
      isNBCUniDomain(env)
    ) {
      removeAllCookiesByName(cookieName, envForHost)
    }
  } else {
    document.cookie = `${cookieName}=; Max-Age=0`
  }
}

export const createOptInAgreementCookieName = (identifier, userId) => `${OPT_IN_COOKIE_PREFIX}:${identifier}:${userId}`

export const getExistingOptInAgreementIdentifiers = (profile, optIns) => {
  const optInAgreementIdentifiers = []
  if (optIns) {
    optIns.forEach((optIn) => {
      const cookieIdentifier = createOptInAgreementCookieName(optIn.identifier, profile._id)
      const existingCookie = getCookie(cookieIdentifier)
      if (existingCookie) optInAgreementIdentifiers.push(optIn.identifier)
    })
  }
  return optInAgreementIdentifiers
}

export const setCookieFromBase64 = (value, expire, env) => {
  const cookieArray = value.split('|')
  cookieArray.forEach((cookie) => {
    const tempCookie = cookie.split('=')
    setCookie(tempCookie[0].trim(), tempCookie[1], expire, env)
  })
}

export const setOptinCookiesFromLocalStorage = () => {
  const cookies = getLocalStorageItem(localStorageItemNames.OPTIN_COOKIES)
  if (cookies) {
    Object.entries(cookies).forEach(([cookieName, cookieObject]) => {
      setCookie(
        cookieName,
        cookieObject.value,
        cookieObject.expirationDate,
        cookieObject.env,
        cookieObject.isCrossDomain,
      )
    })
  }
}

export const isUserMissingRequiredOptIns = (config, profileId) => {
  if (!profileId) return null
  return ConfigHelpers.getOptInsForProfileCompleteCheck(config)
    .some(({ identifier, required }) => {
      const cookieIdentifier = createOptInAgreementCookieName(identifier, profileId)
      return required && getCookie(cookieIdentifier) == null
    })
}
