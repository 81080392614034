// Need to disable this rule to keep signature as
// it wraps/work as a proxy for KMP calls
/* eslint-disable class-methods-use-this */

import {
  IdmService,
  PageName,
  OpenType,
  getCompleteProfilePageName,
  Profile,
  SignOutEventParams,
  EventParams,
  SignOutType,
} from './kmp'
import BaseApi from './baseApi'
import { fixGender } from '../helpers/kmp'
import { isEmpty } from '../helpers/stringHelper'

export default class IdmApiClient extends BaseApi {
  register({
    email,
    password,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    state,
    zipCode,
    vppaOptIn,
    smsConsent,
  }) {
    const eventData = this.createEventParams(PageName.CREATE_PROFILE_FIRST_PARTY, OpenType.Launch)
    return IdmService.register(
      eventData,
      email,
      password,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      zipCode,
      state,
      vppaOptIn,
      smsConsent,
    )
  }

  login({ email, password, vppaOptIn }) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    return IdmService.getSession(eventData, email, password, vppaOptIn)
  }

  registerGoogle({
    clientType,
    idToken,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    state,
    zipCode,
    vppaOptIn,
    smsConsent,
  }) {
    const eventData = this.createEventParams(
      PageName.CREATE_PROFILE_THIRD_PARTY_GOOGLE,
      OpenType.Launch,
    )
    return IdmService.registerGoogle(
      eventData,
      clientType,
      idToken,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      zipCode,
      state,
      vppaOptIn,
      smsConsent,
    )
  }

  loginGoogle({ clientType, idToken, vppaOptIn }) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    return IdmService.getSessionGoogle(eventData, clientType, idToken, vppaOptIn)
  }

  registerApple({
    idToken,
    email,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    state,
    zipCode,
    vppaOptIn,
    smsConsent,
  }) {
    const eventData = this.createEventParams(
      PageName.CREATE_PROFILE_THIRD_PARTY_APPLE,
      OpenType.Launch,
    )
    return IdmService.registerApple(
      eventData,
      idToken,
      email,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      zipCode,
      state,
      vppaOptIn,
      smsConsent,
    )
  }

  loginApple({ idToken, vppaOptIn }) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    return IdmService.getSessionApple(eventData, idToken, vppaOptIn)
  }

  validateEmail({ email, validateRemotely = true }) {
    if (validateRemotely) return IdmService.validateEmail(email)
    return IdmService.validateEmailLocally(email)
  }

  validatePassword({ password, email }) {
    return IdmService.validatePasswordLocally(password, email)
  }

  getProfile({ pageName, pageType, referringPage }) {
    const eventData = new EventParams(pageName, '', referringPage, pageType)
    return IdmService.getProfile(eventData)
  }

  validateSession({ pageName, pageType, referringPage }) {
    const eventData = new EventParams(pageName, '', referringPage, pageType)
    return IdmService.validateSession(eventData)
  }

  updateProfileFromPublicApi({ profile, updatedFields }, pageName, pageType) {
    const eventData = new EventParams(pageName, '', 'None', pageType, OpenType.Launch.openTypeEventName)
    return this.#updateProfile({ profile, updatedFields }, eventData, false)
  }

  updateProfile({ profile, updatedFields, isNewProfile, smsConsent }) {
    const pageName = isNewProfile === undefined
      ? PageName.UPDATE_PROFILE
      : getCompleteProfilePageName(isNewProfile)

    const eventData = this.createEventParams(pageName, OpenType.Launch)
    return this.#updateProfile({ profile, updatedFields }, eventData, smsConsent)
  }

  #updateProfile({ profile, updatedFields: { firstName, lastName, birthYear, gender,
    postalAddress, address2, city, state, zipCode, telephoneNumber } }, eventData, smsConsent) {
    const normalize = (newValue, currentValue) => (newValue === undefined ? currentValue : newValue)

    const updatedProfile = new Profile(
      profile._id,
      profile._rev,
      profile.userName,
      profile.mail,
      normalize(gender?.genderSerialName, profile.gender),
      normalize(birthYear, profile.birthYear),
      profile.episodeCount,
      normalize(firstName, profile.givenName),
      normalize(lastName, profile.sn),
      normalize(telephoneNumber, profile.telephoneNumber),
      normalize(postalAddress, profile.postalAddress),
      normalize(address2, profile.address2),
      normalize(city, profile.city),
      normalize(zipCode, profile.serviceZip),
      normalize(state, profile.stateProvince),
    )

    return IdmService.updateProfile(eventData, updatedProfile, smsConsent)
  }

  logout({
    pageName,
    pageType,
    signOutType,
    parkFr,
    oauthData: { parkFrAccess, parkFrRefresh, clientId },
  }) {
    // calling logout only if parkFr cookie exists
    if (parkFr && !isEmpty(parkFr)) {
      IdmService.logout(new SignOutEventParams(pageName, pageType, 'None', signOutType))
    }

    if (parkFrAccess && clientId) {
      // Fire and forget, no need to wait for result
      IdmService.revokeOauthToken(parkFrAccess, clientId)
    }

    if (parkFrRefresh && clientId) {
      // Fire and forget, no need to wait for result
      IdmService.revokeOauthToken(parkFrRefresh, clientId)
    }
  }

  deleteProfile({ pageName, pageType }) {
    const signOutEventData = new SignOutEventParams(pageName, pageType, 'None', SignOutType.Automatic)
    return IdmService.deleteProfileAndLogout(signOutEventData)
  }

  registerEmailOnly({
    email,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    state,
    zipCode,
  }) {
    const eventData = this.createEventParams(PageName.CREATE_PROFILE_EMAIL_ONLY, OpenType.Launch)
    return IdmService.registerEmailOnly(
      eventData,
      email,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      zipCode,
      state,
    )
  }

  requestOneTimeCode(email) {
    return IdmService.requestOneTimeCode(email)
  }

  updateVPPAConsent() {
    return IdmService.updateVPPAConsent()
  }

  loginWithOneTimeCode({ code, authToken, vppaOptIn }) {
    const eventData = this.createEventParams(PageName.ENTER_ONE_TIME_CODE_AUTH, OpenType.Launch)
    return IdmService.loginWithOneTimeCode(code, authToken, vppaOptIn, eventData)
  }

  loginWithToken(token) {
    return IdmService.loginWithToken(token)
  }

  initPasskey(authMode) {
    return IdmService.passkeyInit(authMode)
  }

  finalizePasskeyCreation(authId, { clientData, keyData, rawId, errorMessage }) {
    if (clientData && keyData && rawId) {
      return IdmService.passkeyFinalizeRegistrationWithSuccess(authId, clientData, keyData, rawId)
    }

    return IdmService.passkeyFinalizeRegistrationWithError(authId, errorMessage)
  }

  finalizePasskeyAuthentication(
    authId,
    { clientData,
      authenticatorData,
      signature,
      rawId,
      userHandle,
      errorMessage },
  ) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    if (clientData && authenticatorData && signature && rawId && userHandle) {
      return IdmService.passkeyFinalizeAuthenticationWithSuccess(
        authId,
        clientData,
        authenticatorData,
        signature,
        rawId,
        userHandle,
        eventData,
      )
    }

    return IdmService.passkeyFinalizeAuthenticationWithError(authId, errorMessage, eventData)
  }

  getBrandLogos() {
    return IdmService.brandLogos()
  }

  getAddresses() {
    return IdmService.getAddresses()
  }

  addAddress(address) {
    return IdmService.addAddress(address)
  }

  updateAddress(id, address) {
    return IdmService.updateAddress(id, address)
  }

  updateAddressAsPreferred(id) {
    return IdmService.updateAddressAsPreferred(id)
  }

  updateAddressAsNotPreferred(id) {
    return IdmService.updateAddressAsNotPreferred(id)
  }

  deleteAddress(id) {
    return IdmService.deleteAddress(id)
  }

  getOneTrustToken() {
    return IdmService.getOneTrustToken()
  }
}
