import { createComputedPropertyLessClone } from '../helpers/jsonHelper'

export default class LaunchDarklyConfiguration {
  #remoteConfiguration

  #cachedConfiguration

  constructor(remoteConfiguration) {
    this.#remoteConfiguration = remoteConfiguration
    this.#cachedConfiguration = {}
    this.refresh()
  }

  refresh() {
    // remoteConfiguration's method definitions are stored in the object's prototypes
    const configPrototype = Object.getPrototypeOf(this.#remoteConfiguration)
    const descriptors = Object.getOwnPropertyDescriptors(configPrototype)

    // loops through all of remotesConfig's methods, calls the methods and stores the result in
    // a key/value pair where the key is the method's name
    const configurationMap = Object.keys(descriptors).reduce((previousValue, key) => {
      const configFunction = descriptors[key].value
      if (typeof configFunction === 'function' && configFunction !== this.#remoteConfiguration.constructor) {
        const result = configFunction.call(this.#remoteConfiguration)
        if (result === null || result === undefined) {
          previousValue[key] = result
        } else if (Array.isArray(result)) {
          previousValue[key] = Object.values(createComputedPropertyLessClone(result))
        } else if (typeof result === 'object') {
          previousValue[key] = createComputedPropertyLessClone(result)
        } else {
          previousValue[key] = result
        }
      }
      return previousValue
    }, {})

    Object.assign(this.#cachedConfiguration, configurationMap)
  }

  get configuration() {
    return this.#cachedConfiguration
  }

  get firstPartyDataInputConfiguration() {
    return [...this.#remoteConfiguration.firstPartyDataInputConfiguration()]
  }
}
