/* eslint-disable no-underscore-dangle */
import * as LDClient from 'launchdarkly-js-client-sdk'

import Logger from '../util/logger'
import { IDENTITY_SDK_VERSION } from '../version'

const IDENTITY_OVERRIDES_KEY = 'identity_ld_overrides'

export default class WebRemoteConfigProvider {
  #brand

  #product

  _logger

  _client

  _ready = false

  #crossDomain = false

  #overrides = {}

  #key

  #checkoutEnabled = false

  customProperties = {}

  constructor(key, brand, product, debug, checkoutEnabled, crossDomain) {
    this.#brand = brand
    this.#product = product
    this._logger = new Logger('BrandWebRemoteConfigProvider', debug)
    this.#crossDomain = crossDomain
    this.#key = key
    this.#checkoutEnabled = checkoutEnabled

    const savedOverrides = localStorage.getItem(IDENTITY_OVERRIDES_KEY)
    if (savedOverrides) {
      this.#overrides = WebRemoteConfigProvider.pruneOverrides(JSON.parse(savedOverrides))
    }
  }

  static pruneOverrides(overrides = {}) {
    const prunedOverrides = {}
    Object.entries(overrides).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        prunedOverrides[key] = value
      }
    })
    return prunedOverrides
  }

  async setUp(profile, callback = null) {
    const mParticleId = 'unknown' // MParticle library is not available during setUp
    const user = this.#buildLDUser(mParticleId)
    this._logger.log('profile', profile)
    this._logger.log('user', user)
    this._client = LDClient.initialize(this.#key, user, this._getInitializationOptions())

    this._client.on('ready', () => {
      this._ready = true
      this._logger.log('LaunchDarkly Ready')
      callback?.()
    })
  }

  updateCheckoutEnabled(checkoutEnabled) {
    this.#checkoutEnabled = checkoutEnabled
  }

  getBoolean(name, defaultValue) {
    if (!this._ready) {
      this._logger.log('Trying to get config before ready')
      return defaultValue
    }
    return this.#flagsWithOverrides[name] ?? defaultValue
  }

  getString(name, defaultValue) {
    if (!this._ready) {
      this._logger.log('Trying to get config before ready')
      return defaultValue
    }
    return this.#flagsWithOverrides[name] ?? defaultValue
  }

  getInt(name, defaultValue) {
    if (!this._ready) {
      this._logger.log('Trying to get config before ready')
      return defaultValue
    }
    return this.#flagsWithOverrides[name] ?? defaultValue
  }

  getDouble(name, defaultValue) {
    if (!this._ready) return defaultValue
    return this.#flagsWithOverrides[name] ?? defaultValue
  }

  getJson(name) {
    if (!this._ready) {
      this._logger.log('Trying to get config before ready')
      return null
    }
    return JSON.stringify(this.#flagsWithOverrides[name])
  }

  allFlags() {
    const allFlags = this._client.allFlags()
    return Object.keys(allFlags).reduce((acc, key) => {
      if (!allFlags[key]) return acc
      return { ...acc, [key]: allFlags[key] }
    }, {})
  }

  allOverrides() {
    return this.#overrides
  }

  resetOverrides() {
    this.#overrides = {}
    localStorage.removeItem(IDENTITY_OVERRIDES_KEY)
  }

  setOverrides(overrides) {
    const prunedOverrides = WebRemoteConfigProvider.pruneOverrides(overrides)
    this.#overrides = { ...this.#overrides, ...prunedOverrides }
    localStorage.setItem(IDENTITY_OVERRIDES_KEY, JSON.stringify(this.#overrides))
  }

  async updateIdentity(identity) {
    this._logger.log('Updating user identity to', identity)
    return new Promise((resolve) => {
      const currentUser = window.mParticle?.Identity?.getCurrentUser
        ? window.mParticle.Identity.getCurrentUser() : null
      const mParticleId = currentUser?.getMPID()
      const user = this.#buildLDUser(mParticleId)
      this._client.identify(user, null, () => resolve())
    })
  }

  #buildLDUser(mParticleId) {
    return {
      ...this._getCommonBuildUserData(),
      key: mParticleId,
      checkoutEnabled: this.#checkoutEnabled,
    }
  }

  _getCommonBuildUserData() {
    return {
      kind: 'user',
      'device-os': 'Web',
      'idm-sdk-version': IDENTITY_SDK_VERSION,
      brand: this.#brand,
      product: this.#product,
      crossDomain: this.#crossDomain,
    }
  }

  _getInitializationOptions() {
    return {
      logger: {
        debug: (message) => this._logger.log('[debug]', message),
        info: (message) => this._logger.log('[info]', message),
        warn: (message) => this._logger.log('[warn]', message),
        error: (message) => this._logger.log('[error]', message),
      },
    }
  }

  get #flagsWithOverrides() {
    return {
      ...this._client.allFlags(),
      ...this.#overrides,
    }
  }
}
