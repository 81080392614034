// Polyfills for IE11 and Edge
import 'core-js/fn/array/includes'
import 'core-js/fn/object/assign'
import 'core-js/fn/object/entries'
import 'core-js/fn/object/values'
import 'core-js/fn/string/ends-with'
import 'core-js/fn/string/includes'
import 'core-js/fn/promise'
import 'url-polyfill'
import 'whatwg-fetch'

import Identity from './identity'

const identity = new Identity()

// eslint-disable-next-line import/prefer-default-export
export { identity }
