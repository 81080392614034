/**
 *
 * Due to name mangling, KotlinJS suggests that developers use @JSName so properties maintain a
 * consistent name when accessed from JS. Under the hood, @JSName creates a computed getter that
 * points to the specified value. Unfortunately, since getters are just functions, they do not get
 * copied over when the object is cloned during a postMessage call. The work around this, the
 * solution is to recursively ( :( ), go through each property & function in @obj to compute the
 * getter call and store the value in a property of the same name. If one of obj's properties is an
 * object, then the same process must recursively be applied to that object
 *
 * @param {*} obj - The object whose computed properties need to be converted into stored properties
 * @returns
 */
export const createComputedPropertyLessClone = (obj) => {
  if (isEmptyObject(obj)) return obj
  const prototype = Object.getPrototypeOf(obj)
  // there are no functions or getters in object, no values to compute.
  // just return object as-is.
  if (!prototype) return obj

  const descriptors = {
    ...Object.getOwnPropertyDescriptors(prototype),
    ...Object.getOwnPropertyDescriptors(obj),
  }
  const getters = Object.keys(descriptors).reduce((getterObject, key) => {
    const value = descriptors[key]
    // If descriptor is a getter, compute the value. If it's an object,
    // recursively call this method.
    // If not, store computed value in a stored property of the same name
    if (value.get) {
      const computedValue = value.get.call(obj)
      getterObject[key] = computedValue && typeof computedValue === 'object' ? createComputedPropertyLessClone(computedValue) : computedValue
    } else if (value.value && typeof value.value === 'object') {
      getterObject[key] = createComputedPropertyLessClone(value.value)
    }
    return getterObject
  }, {})

  return { ...obj, ...getters }
}

export const convertEnumToCloneableObject = (kotlinEnum) => {
  const enumObject = kotlinEnum.values().reduce((prev, current) => {
    prev[current.name] = current
    return prev
  }, {})
  return createComputedPropertyLessClone(enumObject)
}

export const isEmptyObject = (obj) => obj && // 👈 null and undefined check
    Object.keys(obj).length === 0
