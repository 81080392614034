/* eslint-disable class-methods-use-this */

export default class CovaticEventLogger {
  notifyMediaConsumptionEvent(
    mediaAssetIdentifier,
    mediaAssetTitle,
    metadata,
    mediaTags,
    mediaSubsection,
    mediaAssetDuration,
    playHeadPosition,
    mediaAction,
    assetType,
  ) {
    if (!covaticBrowserSDK) { // eslint-disable-line no-undef
      console.error('Covatic is not initialized')
      return
    }

    covaticBrowserSDK.notifyEvent( // eslint-disable-line no-undef
      new covaticBrowserSDK.MediaConsumption( // eslint-disable-line no-undef
        mediaAssetIdentifier,
        mediaAssetTitle,
        metadata,
        mediaTags,
        mediaSubsection,
        mediaAssetDuration,
        playHeadPosition,
        mediaAction,
        assetType,
      ),
    )
  }
}
