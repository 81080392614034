import {
  AuthenticationFlow,
  PageName,
  SignInType,
  ClickLocation,
  AnalyticsErrorType,
  AnalyticsErrorSystem,
  Gender,
} from '../networking/kmp'

/**
  * When we send a Kotlin enum through the Window.postMessage API, it gets converted to a plain
  * object. This function converts it back to a Kotlin enum so Kotlin Extensions and Types work as
  * expected.
  */
const fixKotlinEnumValue = (kotlinEnum, value) => kotlinEnum.values()
  .find((entry) => entry.name === value?.name)

export const fixPageName = (value) => fixKotlinEnumValue(PageName, value)
export const fixSignInType = (value) => fixKotlinEnumValue(SignInType, value)
export const fixAuthenticationFlow = (value) => fixKotlinEnumValue(AuthenticationFlow, value)
export const fixClickLocation = (value) => fixKotlinEnumValue(ClickLocation, value)
export const fixErrorType = (value) => fixKotlinEnumValue(AnalyticsErrorType, value)
export const fixErrorSystem = (value) => fixKotlinEnumValue(AnalyticsErrorSystem, value)
export const fixGender = (value) => fixKotlinEnumValue(Gender, value)
