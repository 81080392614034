/* eslint-disable class-methods-use-this */

import { IdmService } from '../kmp'

export default class PreferencesAPI {
  async getUserPreferences(skipCache = false) {
    return IdmService.getUserPreferences(skipCache)
  }

  async addUserPreferences(preferences) {
    return IdmService.addUserPreferences(preferences)
  }

  async removeUserPreferences(preferences) {
    return IdmService.removeUserPreferences(preferences)
  }

  async setUserPreferences(preferences) {
    return IdmService.setUserPreferences(preferences)
  }

  async getSteps(brandKey) {
    return IdmService.getPreferenceSteps(brandKey)
  }

  async getOptions(step, selection = []) {
    return IdmService.getPreferenceOptions(step, selection)
  }

  async filterOptions(brandKey, steps = [], query = '') {
    return IdmService.filterPreferences(brandKey, steps, query)
  }
}
