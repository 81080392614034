/**
 * Recovers item from the local storage
 * @param {string} key
 */
export const getLocalStorageItem = (key, parse = true) => {
  const stringifiedValue = window.localStorage?.getItem(key)
  return parse ? JSON.parse(stringifiedValue) : stringifiedValue
}

/**
 * Stores item in the local storage
 * @param {string} key
 * @param {*} value
 */
export const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value)
  window.localStorage?.setItem(key, stringifiedValue)
}

/**
 * Store a cookie information in the local storage as a backup
 * @param {string} cookieName
 * @param {*} value
 * @param {number} expirationDate
 * @param {string} env
 * @param {boolean} isCrossDomain
 */
export const storeOptInCookie = (cookieName, value, expirationDate, env, isCrossDomain) => {
  let cookiesBackup = getLocalStorageItem(localStorageItemNames.OPTIN_COOKIES)
  if (!cookiesBackup) cookiesBackup = {}
  cookiesBackup[cookieName] = { value, expirationDate, env, isCrossDomain }
  setLocalStorageItem(localStorageItemNames.OPTIN_COOKIES, cookiesBackup)
}

export const localStorageItemNames = Object.freeze({
  TOKEN: 'TOKEN',
  OPTIN_COOKIES: 'OPTIN_COOKIES',
})
