// Helpers classes can have multiple functions, so we
// would not have a default on them
/* eslint-disable import/prefer-default-export */

import { isAuthenticatedWithCrossDomain } from './crossDomain'
import {
  getDomainFromHostname,
  isLocalDomain,
  isNBCUniDomain,
} from './domainHelper'

export const buildGPMUrl = (env, language, brand, pathname) => {
  let origin

  if (isLocalDomain()) origin = 'http://localhost:5001'
  else if (isAuthenticatedWithCrossDomain() || isNBCUniDomain(env)) {
    if (env === 'production') origin = 'https://profile.id.nbcuni.com/'
    else origin = `https://${env}-profile.id-envs.nbcuni.com/`
  } else {
    const domain = getDomainFromHostname(env)
    const envPrefix = env === 'production' ? '' : `${env}-`
    origin = `https://${envPrefix}id.${domain}`
  }

  const url = new URL(pathname, origin)
  if (brand) url.searchParams.set('brand', brand)
  if (language) url.searchParams.set('lang', language)

  return url.href
}
