import {
  Gender,
  ConditionType,
  IdmServerErrorTypeNames,
} from '../networking/kmp'
import { convertEnumToCloneableObject, createComputedPropertyLessClone } from '../helpers/jsonHelper'
import {
  SupportedFontFamilies,
  DEFAULT_FONT_FAMILY,
  configOverwriteSchema,
} from '../consts'

/**
 * Identity SDK configuration
 *
 * @property {Map<string, string>} [google={}] - Google config
 * @property {number} sessionTimeout - Session token timeout in seconds, default 1 year
 */
class IdentityConfig {
  /**
   * Returns Promise of IdentityConfig from specified server and with specified App key
   *
   * For each NBC App an IdentityConfig should be stored at: https://id.nbc.com/sdk/config/<key>.json
   * Where <key> is the App key, e.g: example => https://id.nbc.com/sdk/config/example.json
   *
   * For non production environments this URL will change accordingly, e.g. for dev:
   * https://dev-id.nbc.com/sdk/config/example.json
   *
   * @example
   * {
   *   "facebook": {
   *     "appId": "1234", // use a non standard Facebook App
   *     "permissions": ["email", "user_friends"] // besides the usual email, also ask for friends
   *   }
   * }
   *
   * @param {Object} options
   * @param {IdentityServer} [options.server] - IdentityServer to get config from
   * @param {String} [options.key] - Key of App to get configuration for
   * @param {String} [options.configLocationHost] - Custom config host url
   * @return {Promise<IdentityConfig, Error>} - Promise of IdentityConfig
   */

  static request({
    server,
    key,
    configLocationHost,
    language,
    storedConfig = null,
    overwriteLocalConfig = null,
  }) {
    const customConfigLocationBaseUrl = (configLocationHost === 'null' || !configLocationHost)
      ? null
      : configLocationHost.replace(/\/?$/, '/')

    const checkParamsToOverwriteLocalConfig = (config, sourceConfig, configModel) => {
      Object.keys(sourceConfig).forEach((objectKey) => {
        if (
          Object.prototype.hasOwnProperty.call(config, objectKey) &&
          Object.prototype.hasOwnProperty.call(configModel, objectKey)
        ) {
          if (
            typeof configModel[objectKey] === 'object' &&
            typeof config[objectKey] === 'object' &&
            typeof sourceConfig[objectKey] === 'object' &&
            sourceConfig[objectKey] !== null &&
            // eslint-disable-next-line no-param-reassign
            config[objectKey] !== null
          ) {
            checkParamsToOverwriteLocalConfig(
              config[objectKey],
              sourceConfig[objectKey],
              configModel[objectKey],
            )
          } else if (sourceConfig[objectKey]) {
            // eslint-disable-next-line no-param-reassign
            config[objectKey] = sourceConfig[objectKey]
          }
        }
      })

      return config
    }

    const fetchIdentityConfigFile = (brandConfig) => server.fetchIdentityConfigFile()
      .then((identityConfig) => {
        if (overwriteLocalConfig) {
          // eslint-disable-next-line no-param-reassign
          brandConfig = checkParamsToOverwriteLocalConfig(
            brandConfig,
            overwriteLocalConfig,
            configOverwriteSchema,
          )
        }
        return new IdentityConfig(
          { ...brandConfig, ...identityConfig, ...storedConfig, language },
          server,
          customConfigLocationBaseUrl,
          key,
        )
      })

    const handleFetchBranchError = (error) => {
      // Use default logic wihout the configLocationHost override
      if (configLocationHost) {
        window.console.error(
          'configLocationHost property error, using default host to fetch the config file:',
          error,
        )
        return this.request({ server, key, language })
      }

      return Promise.reject(error)
    }

    return server
      .fetchBrandConfigFile(key, language, customConfigLocationBaseUrl)
      .then(fetchIdentityConfigFile, handleFetchBranchError)
  }

  static getConfigurationKey() {
    const nbcAuthenticateUrlRegex =
      /id\.nbc\.com\/websdk\/v[\d.\w.-]+\/authenticate/
    const sportsEdgeAuthenticateUrlRegex =
      /id\.nbcsportsedge\.com\/websdk\/v[\d.\w.-]+\/authenticate/
    const eOnlineAuthenticateUrlRegex =
      /id\.eonline\.com\/websdk\/v[\d.\w.-]+\/authenticate/
    if (window.location.origin.includes('https://localhost:4000')) return 'nbc'
    if (nbcAuthenticateUrlRegex.test(window.location.href)) return 'nbc'
    if (sportsEdgeAuthenticateUrlRegex.test(window.location.href)) return 'sportsedge'
    if (eOnlineAuthenticateUrlRegex.test(window.location.href)) return 'eolweb'
    return 'nbc'
  }

  /**
   * IdentityConfig constructor
   *
   * @param {string} [defaultPage='signUp'] - The default page to load on init
   * @param {Map<string, string>} [google={}] - Google config
   * @param {number} [sessionTimeout=31536000] - Session token timeout in seconds, default 1 year
   * @param {number} [userTimeout=3600] - User data cache timeout in seconds, default 1 hour
   * @param {Object} [theme={}] - Customizable theme parameters
   * @param {Object} [analytics={}] - Analytics settings
   * @param {Object} [zeroBounce={}] - ZeroBounce settings
   * @param {Object} [crossDomain={}] - Customizable crossDomain parameters
   * @param {Object} [covatic={}] - Customizable covatic parameters
   */
  constructor(
    {
      defaultPage = 'signUp',
      forgeRock = { apiUrl: 'https://id.nbcuni.com', apiVersion: '2023.1' },
      forgotPasswordUrl = 'https://profile.id.nbcuni.com/forgot-password',
      zeroBounceApiUrl = 'https://services.id.nbcuni.com/email-validation/',
      helpCenterUrl = 'https://help.nbc.com/hc',
      passKeyIconUrl = 'passkey_icon.svg',
      hideCloseButton = false,
      valueProps = [],
      terms = {},
      optIns = {},
      apple = {},
      appleRedirectUri = 'https://services.id.nbcuni.com/apple-auth/',
      google = {},
      googleRedirectUri = 'https://services.id.nbcuni.com/google-auth/',
      sessionTimeout = 31536000,
      brand = {},
      theme = {},
      analytics = {},
      launchdarkly = {},
      crossDomain = {},
      covatic = {},
      schemaVersion = '2.0',
      language,
    },
    server,
    configLocation,
    key,
  ) {
    const localTheme = { ...theme }

    let customResourceBaseUrl
    if (configLocation) {
      const configPath = 'config/'
      customResourceBaseUrl = configLocation.endsWith(configPath)
        ? configLocation.slice(0, configLocation.lastIndexOf(configPath))
        : configLocation
    }

    localTheme.assetsBaseUrl = server.getResourcesUrl(
      `assets/${key}/`,
      customResourceBaseUrl,
    )
    localTheme.app.fontFamily = SupportedFontFamilies[localTheme.app.fontFamily]
      ?? DEFAULT_FONT_FAMILY
    localTheme.app.headerFontFamily = SupportedFontFamilies[localTheme.app.headerFontFamily]
      ?? DEFAULT_FONT_FAMILY

    const types = {
      Gender: convertEnumToCloneableObject(Gender),
      ConditionType: convertEnumToCloneableObject(ConditionType),
      IdmServerErrorTypeNames: createComputedPropertyLessClone(IdmServerErrorTypeNames),
    }

    // eslint-disable-next-line consistent-return
    const setLanguageForRedirect = (url) => {
      const addLanguageToURL = (redirectURL) => {
        const baseURL = new URL(redirectURL)
        if (language === 'es') baseURL.searchParams.append('lang', 'es')
        return baseURL.toString()
      }
      // eslint-disable-next-line default-case
      switch (url) {
        case forgotPasswordUrl: {
          return addLanguageToURL(url)
        }
        case helpCenterUrl:
          return `${url}/${language === 'es' ? language : 'en-us'}`
      }
    }

    Object.assign(this, {
      defaultPage,
      forgeRock,
      zeroBounceApiUrl,
      passKeyIconUrl,
      forgotPasswordUrl: setLanguageForRedirect(forgotPasswordUrl),
      helpCenterUrl: setLanguageForRedirect(helpCenterUrl),
      hideCloseButton,
      valueProps,
      terms,
      optIns,
      types,
      apple,
      appleRedirectUri,
      google,
      googleRedirectUri,
      sessionTimeout,
      brand,
      theme: localTheme,
      analytics,
      launchdarkly,
      crossDomain,
      covatic,
      schemaVersion,
      key,
    })

    // Disable property modification
    Object.freeze(this)
  }

  getGoogleClientType(os) {
    return this.google[os.toLowerCase()].clientType
  }
}

export default IdentityConfig
