import Observable from './observable'

/**
 *
 * Allows consumers to send and receive messages via a native application bridge.
 * The native application must be set up to create a nativeWrapper window attribute
 * to leverage the bridge calls.
 */
class NativeBridge extends Observable {
  #commandIdCounter = 0

  #ready = false

  #wrapperPromise

  /**
     * NativeBridge constructor
     */
  constructor() {
    super()
    this.#wrapperPromise = new Promise((resolve, reject) => {
      // Reject after timeout
      const timeout = setTimeout(reject, 5000)
      const callbackCleanup = this.on('nativeWrapperIsReady', (data) => {
        if (data?.ready === true) {
          this.#ready = true
          clearTimeout(timeout)
          callbackCleanup()
          resolve(true)
        }
      })
    }).then((() => this))

    if (window.nativeWrapper?.sendMessage) {
      this.#wrapperReady()
      this.sendMessage({ type: 'Event', subject: 'AppReady' })
    } else {
      window.nativeWrapper = {}
    }

    window.nativeWrapper.onMessage = this.onMessage.bind(this)
  }

  #wrapperReady() {
    this.notify('nativeWrapperIsReady', { ready: true })
  }

  getWrapper() {
    return new Promise((resolve, reject) => {
      this.#wrapperPromise.then(resolve, reject)
    })
  }

  subscribe(callback, type = 'CommandResult') {
    return this.on(type, callback)
  }

  /**
     * Listen for messages from the native wrapper and add to the event bus
     *
     * @param {Object} message
     */
  onMessage(message) {
    const messageType = message.type ?? 'Event'
    const { subject } = message

    if (!this.#ready && subject === 'BridgeReady') {
      this.#wrapperReady()
    }

    this.notify(messageType, { subject, message })
  }

  sendMessage(message) {
    return this.#wrapperPromise.then(() => window.nativeWrapper.sendMessage(message))
  }

  /**
     * Send a command to the native wrapper
     * @param {*} subject - The subject of the command
     * @param {*} payload - The payload of the command
     */
  sendCommand(subject, payload = {}) {
    const commandId = this.#commandIdCounter.toString()
    this.#commandIdCounter += 1

    this.sendMessage({
      type: 'Command', subject, commandId, payload,
    })
  }

  /**
     * Send a command to the native wrapper and returns a promise
     * that is resolved when the app sends the result.
     * @param {*} subject - The subject of the command
     * @param {*} payload - The payload of the command
     */
  sendCommandAndWaitResult(subject, payload = {}) {
    const commandId = this.#commandIdCounter.toString()
    this.#commandIdCounter += 1

    const message = {
      type: 'Command', subject, commandId, payload,
    }

    const resultPromise = this.#waitForCommandResult(message)

    return this.sendMessage(message)
      .then(() => resultPromise)
  }

  #waitForCommandResult(message) {
    return new Promise((resolve) => {
      const removeObserver = this.subscribe(({ message: receivedMessage }) => {
        const isResultMessage = receivedMessage &&
                    receivedMessage.subject === message.subject &&
                    receivedMessage.commandId === message.commandId

        if (isResultMessage) {
          removeObserver()
          resolve(receivedMessage)
        }
      })
    })
  }
}

let nativeBridge

// eslint-disable-next-line import/prefer-default-export
export const getNativeBridge = () => {
  if (!nativeBridge) nativeBridge = new NativeBridge()
  return nativeBridge.getWrapper().catch(() => {
    nativeBridge = null
  })
}
