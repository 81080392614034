// Need to disable this rule to keep signature as
// it wraps/work as a proxy for KMP calls
/* eslint-disable class-methods-use-this */

import {
  EventParams,
  getCompleteProfilePageName,
  IdmService,
  OpenType,
  PageName,
  Profile,
  SignOutEventParams,
  SignOutType,
} from './kmp'
import BaseApi from './baseApi'
import { fixGender } from '../helpers/kmp'

export default class IdmApiClient extends BaseApi {
  register({
    email,
    password,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    state,
    zipCode,
    smsConsent,
  }) {
    const eventData = this.createEventParams(PageName.CREATE_PROFILE_FIRST_PARTY, OpenType.Launch)
    return IdmService.register(
      eventData,
      email,
      password,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      birthMonth,
      birthDay,
      zipCode,
      state,
      smsConsent,
    )
  }

  login({ email, password }) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    return IdmService.getSession(eventData, email, password)
  }

  registerGoogle({
    clientType,
    idToken,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    state,
    zipCode,
    smsConsent,
  }) {
    const eventData = this.createEventParams(
      PageName.CREATE_PROFILE_THIRD_PARTY_GOOGLE,
      OpenType.Launch,
    )
    return IdmService.registerGoogle(
      eventData,
      clientType,
      idToken,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      birthMonth,
      birthDay,
      zipCode,
      state,
      smsConsent,
    )
  }

  loginGoogle({ clientType, idToken }) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    return IdmService.getSessionGoogle(eventData, clientType, idToken)
  }

  registerApple({
    idToken,
    email,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    state,
    zipCode,
    smsConsent,
  }) {
    const eventData = this.createEventParams(
      PageName.CREATE_PROFILE_THIRD_PARTY_APPLE,
      OpenType.Launch,
    )
    return IdmService.registerApple(
      eventData,
      idToken,
      email,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      birthMonth,
      birthDay,
      zipCode,
      state,
      smsConsent,
    )
  }

  loginApple({ idToken }) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    return IdmService.getSessionApple(eventData, idToken)
  }

  validateEmail({ email, validateRemotely = true }) {
    if (validateRemotely) return IdmService.validateEmail(email)
    return IdmService.validateEmailLocally(email)
  }

  validatePassword({ password, email }) {
    return IdmService.validatePasswordLocally(password, email)
  }

  getProfile({ pageName, pageType, referringPage }) {
    const eventData = new EventParams(pageName, '', referringPage, pageType)
    return IdmService.getProfile(eventData)
  }

  validateSession({ pageName, pageType, referringPage }) {
    const eventData = new EventParams(pageName, '', referringPage, pageType)
    return IdmService.validateSession(eventData)
  }

  updateProfileFromPublicApi({ profile, updatedFields }, pageName, pageType) {
    const eventData = new EventParams(pageName, '', 'None', pageType, OpenType.Launch.openTypeEventName)
    return this.#updateProfile({ profile, updatedFields }, eventData, false)
  }

  updateProfile({ profile, updatedFields, isNewProfile, smsConsent }) {
    const pageName = isNewProfile === undefined
      ? PageName.UPDATE_PROFILE
      : getCompleteProfilePageName(isNewProfile)

    const eventData = this.createEventParams(pageName, OpenType.Launch)
    return this.#updateProfile({ profile, updatedFields }, eventData, smsConsent)
  }

  #updateProfile({
    profile,
    updatedFields: {
      firstName,
      lastName,
      birthYear,
      birthMonth,
      birthDay,
      gender,
      postalAddress,
      address2,
      city,
      state,
      zipCode,
      telephoneNumber,
    },
  }, eventData, smsConsent) {
    const normalize = (newValue, currentValue) => (newValue === undefined ? currentValue : newValue)

    const updatedProfile = new Profile(
      profile._id,
      profile._rev,
      profile.userName,
      profile.mail,
      normalize(gender?.genderSerialName, profile.gender),
      normalize(birthYear, profile.birthYear),
      normalize(birthMonth, profile.birthMonth),
      normalize(birthDay, profile.birthDay),
      profile.episodeCount,
      normalize(firstName, profile.givenName),
      normalize(lastName, profile.sn),
      normalize(telephoneNumber, profile.telephoneNumber),
      normalize(postalAddress, profile.postalAddress),
      normalize(address2, profile.address2),
      normalize(city, profile.city),
      normalize(zipCode, profile.serviceZip),
      normalize(state, profile.stateProvince),
    )

    return IdmService.updateProfile(eventData, updatedProfile, smsConsent)
  }

  logout({ pageName, pageType, signOutType }) {
    return IdmService.logout(new SignOutEventParams(pageName, pageType, 'None', signOutType))
  }

  deleteProfile({ pageName, pageType }) {
    const signOutEventData = new SignOutEventParams(pageName, pageType, 'None', SignOutType.Automatic)
    return IdmService.deleteProfileAndLogout(signOutEventData)
  }

  registerEmailOnly({
    email,
    firstName,
    lastName,
    telephoneNumber,
    gender,
    birthYear,
    birthMonth,
    birthDay,
    state,
    zipCode,
    pageName = null,
    pageType = null,
    registrationReferrer = null,
    referringPage = null,
  }) {
    this.setReferralInfo(registrationReferrer, referringPage)
    const pageNameObject = {
      pageEventName: pageName, pageType: { pageTypeEventName: pageType },
    }
    const eventData = this.createEventParams(pageName && pageType ? pageNameObject
      : PageName.CREATE_PROFILE_EMAIL_ONLY, OpenType.Launch)
    return IdmService.registerEmailOnly(
      eventData,
      email,
      firstName,
      lastName,
      telephoneNumber,
      fixGender(gender),
      birthYear,
      birthMonth,
      birthDay,
      zipCode,
      state,
    )
  }

  requestOneTimeCode(email) {
    return IdmService.requestOneTimeCode(email)
  }

  loginWithOneTimeCode({
    code,
    authToken,
    pageName = null,
    pageType = null,
    registrationReferrer = null,
    referringPage = null,
  }) {
    this.setReferralInfo(registrationReferrer, referringPage)

    const pageNameObject = pageName && pageType ? {
      pageEventName: pageName, pageType: { pageTypeEventName: pageType },
    } : PageName.ENTER_ONE_TIME_CODE_AUTH

    const eventData = this.createEventParams(pageNameObject, OpenType.Launch)

    return IdmService.loginWithOneTimeCode(code, authToken, eventData)
  }

  loginWithToken(token) {
    return IdmService.loginWithToken(token)
  }

  initPasskey(authMode) {
    return IdmService.passkeyInit(authMode)
  }

  finalizePasskeyCreation(authId, { clientData, keyData, rawId, errorMessage }) {
    if (clientData && keyData && rawId) {
      return IdmService.passkeyFinalizeRegistrationWithSuccess(authId, clientData, keyData, rawId)
    }

    return IdmService.passkeyFinalizeRegistrationWithError(authId, errorMessage)
  }

  finalizePasskeyAuthentication(
    authId,
    {
      clientData,
      authenticatorData,
      signature,
      rawId,
      userHandle,
      errorMessage,
    },
  ) {
    const eventData = this.createEventParams(PageName.LOGIN, OpenType.Launch)
    if (clientData && authenticatorData && signature && rawId && userHandle) {
      return IdmService.passkeyFinalizeAuthenticationWithSuccess(
        authId,
        clientData,
        authenticatorData,
        signature,
        rawId,
        userHandle,
        eventData,
      )
    }

    return IdmService.passkeyFinalizeAuthenticationWithError(authId, errorMessage, eventData)
  }

  getBrandLogos() {
    return IdmService.brandLogos()
  }

  getAddresses() {
    return IdmService.getAddresses()
  }

  addAddress(address) {
    return IdmService.addAddress(address)
  }

  updateAddress(id, address) {
    return IdmService.updateAddress(id, address)
  }

  updateAddressAsPreferred(id) {
    return IdmService.updateAddressAsPreferred(id)
  }

  updateAddressAsNotPreferred(id) {
    return IdmService.updateAddressAsNotPreferred(id)
  }

  deleteAddress(id) {
    return IdmService.deleteAddress(id)
  }

  getOneTrustToken() {
    return IdmService.getOneTrustToken()
  }
}
