// Need to disable this to keep signature
/* eslint-disable class-methods-use-this */

import { getNativeBridge } from '../nativeBridge'
import { EventLoggerStatus } from '../consts'

const Subjects = {
  GET_EVENT_TYPES: 'MParticleGetEventTypes',
  LOG_EVENT: 'MParticleLogEvent',
  IDENTIFY: 'MParticleIdentify',
  LOG_IN: 'MParticleLogin',
  LOG_OUT: 'MParticleLogout',
  SET_ATTRIBUTE: 'MParticleSetUserAttribute',
  GET_USER_ATTRIBUTES: 'MParticleGetUserAttributes',
}
export const ObservableEvents = Object.freeze({
  READY: 'READY',
  IDENTITY_CHANGE: 'IDENTITY_CHANGE',
})

export default class NativeEventLogger {
  #status = EventLoggerStatus.UNINITIALIZED

  #pendingEvents = []

  #eventTypes = null

  #eventListeners = {}

  customEventAttributes = {}

  constructor(customEventAttributes) {
    this.customEventAttributes = customEventAttributes
  }

  get isReady() {
    return this.#status === EventLoggerStatus.READY
  }

  get isInitialized() {
    return this.isReady || this.#status === EventLoggerStatus.INITIALIZED
  }

  setUp(profile) {
    if (this.#status !== EventLoggerStatus.UNINITIALIZED) return
    this.#status = EventLoggerStatus.INITIALIZED
    getNativeBridge()
      .then((bridge) => bridge.sendCommandAndWaitResult(Subjects.GET_EVENT_TYPES))
      .then((result) => {
        this.#eventTypes = result.payload
        this.#status = EventLoggerStatus.READY
        this.#notifyListeners(ObservableEvents.READY)
        if (!profile) this.#setDefaultUserAttributes()
        this.#processQueuedEvents()
      })
  }

  logout() {
    return getNativeBridge()
      .then((bridge) => bridge.sendCommandAndWaitResult(Subjects.LOG_OUT))
  }

  login(identity) {
    const nativeIdentity = this.#mapWebIdentitiesToNative(identity)
    return getNativeBridge()
      .then((bridge) => bridge.sendCommandAndWaitResult(Subjects.LOG_IN, nativeIdentity))
  }

  updateIdentity(identity) {
    const nativeIdentity = this.#mapWebIdentitiesToNative(identity)
    return getNativeBridge()
      .then((bridge) => bridge.sendCommandAndWaitResult(Subjects.IDENTIFY, nativeIdentity))
  }

  updateUserAttributes(attributes) {
    getNativeBridge().then((bridge) => {
      Object.keys(attributes).forEach((key) => {
        bridge.sendCommand(Subjects.SET_ATTRIBUTE, { name: key, value: attributes[key] })
      })
    })
  }

  logEvent(name, type, customAttributes) {
    const allAttributes = { ...customAttributes, ...this.customEventAttributes }
    if (this.isReady) {
      const payload = {
        name,
        type: this.#eventTypes[type],
        properties: allAttributes,
      }
      getNativeBridge().then((bridge) => bridge.sendCommand(Subjects.LOG_EVENT, payload))
    } else {
      this.#pendingEvents.push({ name, type, allAttributes })
    }
  }

  addEventListener(eventKey, listener) {
    if (!(eventKey in ObservableEvents) || typeof listener !== 'function') return

    if (eventKey === ObservableEvents.READY && this.isReady) {
      listener?.()
    } else if (!this.#eventListeners[eventKey]) {
      this.#eventListeners[eventKey] = [listener]
    } else {
      this.#eventListeners[eventKey].push(listener)
    }
  }

  #setDefaultUserAttributes() {
    getNativeBridge()
      .then((bridge) => bridge.sendCommandAndWaitResult(Subjects.GET_USER_ATTRIBUTES))
  }

  #processQueuedEvents() {
    this.#pendingEvents
      .forEach(({ name, type, customAttributes }) => this.logEvent(name, type, customAttributes))
    this.#pendingEvents.length = 0
  }

  #mapWebIdentitiesToNative({ customerid, email }) {
    return { customerId: customerid, email }
  }

  async #notifyListeners(eventKey, ...args) {
    const listeners = this.#eventListeners[eventKey] ?? []
    listeners.forEach((listener) => (async () => {
      listener?.(...args)
    })())
  }
}
