/**
 * Minimal observable class
 */
export default class Observable {
  /**
     * Default observers class variable
     *
     * @type {object}
     */
  #observers = {}

  /**
     * Register callback to be called with events of specified type
     *
     * Callback will be called with an argument depending on the type of event.
     *
     * @example
     * const observable = new Observable()
     * const off = observable.on('foo', (data) => {
     *   console.log(`Foo!`, data)
     * })
     *
     *
     * @param {string} type - Event type
     * @param {Function} callback - Event callback function
     * @return {Function} - Function to be called to unregister this callback
     */
  on(type, callback) {
    if (typeof type !== 'string') {
      throw new Error('Type argument passed to Observable#on() is not specified or not a string')
    }
    if (typeof callback !== 'function') {
      throw new Error('Callback function passed to Observable#on() is not specified or not a function')
    }

    let observersArray = this.#observers[type]
    if (!observersArray) {
      observersArray = []
      this.#observers[type] = observersArray
    }

    if (observersArray.indexOf(callback) === -1) {
      observersArray.push(callback)
    }

    return () => {
      const typeObservers = this.#observers[type]
      const index = typeObservers.indexOf(callback)
      if (index > -1) typeObservers.splice(index, 1)
    }
  }

  /**
     * Notify all observers of specified event type
     *
     * @param {string} type - Event type
     * @param {*} data - Event data
     */
  notify(type, data) {
    if (Array.isArray(this.#observers[type])) {
      // copy observers array so that if a new callback gets added to #observers,
      // it doesn't immediately get notified if we're already going through loop
      [...this.#observers[type]].forEach((callback) => {
        callback(data)
      })
    }
  }
}
