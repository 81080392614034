import { LogHelpers } from '../networking/kmp'

/**
 * Minimal logger class
 *
 * @property {string} prefix - Log prefix
 * @property {boolean} enabled - Whether logger is enabled
 */
class Logger {
  /**
     * Logger constructor
     *
     * @param {string} [prefix=null] - Log prefix,
     * @param {boolean} [enabled=true] - Whether logger is enabled
     */
  constructor(prefix = null, enabled = true) {
    this.prefix = prefix ? LogHelpers.buildTag(prefix) : LogHelpers.baseTag
    this.enabled = enabled
  }

  /**
     * Log arguments with prefix to console if enabled
     */
  log(...args) {
    this.#internalLog(false, console.log, ...args)
  }

  warn(...args) {
    this.#internalLog(true, console.warn, ...args)
  }

  #internalLog(alwaysLog, loggerMethod, ...args) {
    if (this.enabled || alwaysLog) {
      if (window.Cypress) {
        const mappedArgs = args.map((item) => {
          if (item && (Array.isArray(item) || typeof item === 'object')) {
            return JSON.stringify(item)
          }
          return item
        })

        loggerMethod(`[${this.prefix}]`, ...mappedArgs) // eslint-disable-line no-console
      } else {
        loggerMethod(`[${this.prefix}]`, ...args) // eslint-disable-line no-console
      }
    }
  }
}

export default Logger
