export const enableCovaticSdk = async (config, callback) => {
  if (config.url && config.clientConfigString) {
    const script = document.createElement('script')
    script.src = config.url
    script.async = true
    document.head.appendChild(script)
    script.onload = () => {
      covaticBrowserSDK.Client(config.clientConfigString) // eslint-disable-line no-undef
      // Covatic takes over a second to initialize before commenceMedia consumption can be fired
      // Set a timeout before calling the callback to prevent missing consumptions
      setTimeout(() => {
        covaticBrowserSDK.notifyConsent(true) // eslint-disable-line no-undef
        callback()
      }, 2000)
    }
  }
}

export const getAdMeta = () => covaticBrowserSDK.acquireAdMeta() // eslint-disable-line no-undef
